#top-clients-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;  
}

#top-clients-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

#clients-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 2%;
    margin-bottom: 3%;
}

.clients-img {
    width: 50%;
    margin: 0 25%;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    #top-clients-container {
        width: 80%;
        margin: -.1% 10% 0;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #top-clients-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #top-clients-container {
        width: 80%;
        margin: 0 10%;
    }

    #clients-container {
        grid-template-columns: repeat(4, 1fr);
        row-gap: 30px;
    }

    .clients-col:nth-child(9) {
        grid-column: 2 / span 1;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #top-clients-container {
        width: 70%;
        margin: 0 15%;
    }

    #clients-container {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 30px;
    }

    .clients-col:nth-child(10) {
        grid-column: 2 / span 1;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #top-clients-container {
        width: 70%;
        margin: 0 15%;
    }

    #clients-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }

    #top-clients-header {
        text-align: center;
        margin-top: 4%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #top-clients-container {
        width: 70%;
        margin: 0 15%;
    }

    #clients-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }

    #top-clients-header {
        text-align: center;
        margin-top: 4%;
        margin-bottom: 10%;
    }
}

@media (max-width: 516px) {
    #top-clients-container {
        width: 100%;
        margin: 0%;
    }

    #clients-container {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 30px;
    }

    #top-clients-header {
        text-align: center;
        margin-top: 4%;
        margin-bottom: 10%;
    }
}