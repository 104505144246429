#graphic-design-container {
    background-color: #fefcfd;
    background-size: contain;
    width: 60%;
    margin: -0.2% 20% 0;
    padding: 2%;
}

#graphic-design-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
    margin-bottom: 5%;
}

.graphic-design-subheader {
    padding: 2%!important;
    text-decoration: underline;
    text-underline-offset: 18px;
    text-decoration-color: #f11515;
}

.graphic-design-text {
    width: 80%;
    margin: 0 10%;
    font-size: 18px;
}

#graphic-design-results-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 20px;
}

@media (max-width: 2030px) and (min-width: 1700px),
(max-width: 1699px) and (min-width: 1200px),
(max-width: 1199px) and (min-width: 992px) {
    #graphic-design-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 991px) and (min-width: 801px),
(max-width: 800px) and (min-width: 768px) {
    #graphic-design-container {
        width: 70%;
        margin: 0 15%;
    }
    #graphic-design-results-container { 
        grid-template-columns: repeat(2, 1fr);
        row-gap: 20px;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #graphic-design-container {
        width: 70%;
        margin: 0 15%;
    }
    #graphic-design-results-container { 
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
    #graphic-design-header {
        margin-bottom: 10%;
    }
}

@media (max-width: 516px) {
    #graphic-design-container {
        width: 100%;
        margin: 0%;
        padding: 5%;
    }
    #graphic-design-results-container { 
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
    #graphic-design-header {
        margin-bottom: 10%;
    }
}