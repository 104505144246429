#top-catalogs-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#top-catalogs-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

#catalogs-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 2%;
}

.catalog-col:has(#sanmar),
.catalog-col:has(#gemline) {
    margin-top: 7%;
}

.catalog-col:has(#charles-river) {
    margin-top: 12%;
}

.catalog-col:has(#citadel-brands) {
    margin-top: 18%;
}

.catalog-col:has(#cutter-buck) {
    margin-top: 10%;
}

.catalog-col:has(#edwards) {
    margin-top: 20%;
}

.catalog-img {
    width: 50%;
    margin: 0 20%;
}

@media (max-width: 2030px) and (min-width: 1700px) {
    #top-catalogs-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #top-catalogs-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #top-catalogs-container {
        width: 80%;
        margin: 0 10%;
    }

    #catalogs-container {
        grid-template-columns: repeat(4, 1fr);
    }

    .catalog-col:has(#blue-gen) {
        grid-column: 2 / span 1;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #top-catalogs-container {
        width: 70%;
        margin: 0 15%;
        padding-bottom: 3%;
    }

    #catalogs-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .catalog-col:has(#edwards) {
        grid-column: 2 / span 1;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #top-catalogs-container {
        width: 70%;
        margin: 0 15%;
        padding-bottom: 3%;
    }

    #catalogs-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .catalog-col:has(#charles-river) {
        margin-top: 11%;
    }

    #top-catalogs-header {
        text-align: center;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #top-catalogs-container {
        width: 70%;
        margin: 0 15%;
        padding-bottom: 3%;
    }

    #catalogs-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .catalog-col:has(#charles-river) {
        margin-top: 11%;
    }

    #top-catalogs-header {
        text-align: center;
    }
}

@media (max-width: 516px) {
    #top-catalogs-container {
        width: 100%;
        margin: 0%;
        padding-bottom: 3%;
    }

    #catalogs-container {
        grid-template-columns: repeat(2, 1fr);
    }
    .catalog-col:has(#charles-river) {
        margin-top: 11%;
    }
    #top-catalogs-header {
        text-align: center;
        line-height: 60px;
    }
}