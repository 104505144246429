#careers-header {
    padding: 4%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
    text-align: center;
}

#careers-job-list-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

.careers-job-list-item {
    width: 80%;
    margin: 0 10%;
    padding: 5%;
}

.careers-job-list-divider {
    width: 50%!important;
    margin: 3% 25%!important;
    border-top: 1px solid #f11515!important;
    border-bottom: 1px solid #f11515!important;
}