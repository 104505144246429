#page-contact-footer {
    background-image: url(https://zuse-inc-bucket.s3.amazonaws.com/contact-footer.png);
    background-color: #fefcfd;
    background-size: cover;
    width: 100%;
    height: 500px;
    margin: 5% 0% 0%;
    padding: 2%;
}

#page-contact-header {
    padding: 2%;
    margin-top: 2%;
    font-size: 36px;
    text-decoration: underline;
    text-underline-offset: 20px;
    color: #fefcfd;
    text-align: center;
    text-decoration-color: #fefcfd;
}

#page-contact-subheader {
    font-size: 24px;
    width: 40%;
    margin: 0 30%;
    padding: 2%;
    line-height: 40px;
    color: #fefcfd;
    text-align: center;
}

#page-contact-btn {
    width: 25%;
    height: 60px;
    background-color: #f11515;
    border: solid 1px transparent;
    color: #fefcfd;
    font-family: "Lato", "Helvetica Neuve";
    margin: 2% 37.5% 0;
}

@media (max-width: 1199px) and (min-width: 992px) {
    #page-contact-header {
        margin-top: 7%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #page-contact-header {
        margin-top: 12%
    }
    #page-contact-subheader {
        width: 60%;
        margin: 0 20%;
    }
    #page-contact-btn { 
        width: 40%;
        margin: 2% 30% 0;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #page-contact-header {
        margin-top: 14%
    }
    #page-contact-subheader {
        width: 60%;
        margin: 0 20%;
    }
    #page-contact-btn { 
        width: 40%;
        margin: 2% 30% 0;
    }
}

@media (max-width: 767px) and (min-width: 411px) {
    #page-contact-footer {
        background-position: 30%;
    }
    #page-contact-header {
        margin-top: 30%;
        font-size: 24px;
    }
    #page-contact-subheader {
        font-size: 16px;
        width: 70%;
        margin: 0 15%;
    }
    #page-contact-btn { 
        width: 50%;
        margin: 2% 25% 0;
    }
}

@media (max-width: 410px) and (min-width: 362px) {
    #page-contact-footer {
        background-position: 30%;
    }
    #page-contact-header {
        margin-top: 40%;
        font-size: 24px;
    }
    #page-contact-subheader {
        font-size: 16px;
        width: 70%;
        margin: 0 15%;
    }
    #page-contact-btn { 
        width: 60%;
        margin: 2% 20% 0;
    }
}

@media (max-width: 361px) {
    #page-contact-footer {
        background-position: 30%;
    }
    #page-contact-header {
        margin-top: 50%;
        font-size: 24px;
    }
    #page-contact-subheader {
        font-size: 16px;
        width: 80%;
        margin: 0 10%;
    }
    #page-contact-btn { 
        width: 70%;
        margin: 2% 15% 0;
    }
}