#page-banner {
    height: 150px;
    background-position: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.zuse {
    background-image: linear-gradient(rgba(241, 21, 21, 0.8), rgba(241, 21, 21, 0.8)), url(https://zuse-inc-bucket.s3.amazonaws.com/banners/page-banner.png);
}

.acp, .union-print {
    background-image: linear-gradient(rgba(241, 21, 21, 0.8), rgba(241, 21, 21, 0.8)), url(https://zuse-inc-bucket.s3.amazonaws.com/banners/main-banner-textless.png);
}

.page-banner-col {
    width: 50%;
    margin: auto 25%;
}

#page-banner-title {
    color: white!important;
    text-align: center;
}

.breadcrumb-link {
    color: white!important;
}

.breadcrumb-link-divider {
    color: white!important;
}

@media (max-width: 1199px) and (min-width: 992px) {
    .page-banner-col {
        width: 60%;
        margin: auto 20%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    .page-banner-col {
        width: 70%;
        margin: auto 15%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    .page-banner-col {
        width: 75%;
        margin: auto 12.5%;
    }
}

@media (max-width: 767px) and (min-width: 423px) {
    #page-banner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .page-banner-col {
        width: 100%;
        margin: auto 0%;
        text-align: center;
    }
}

@media (max-width: 422px) and (min-width: 362px) {
    #page-banner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .page-banner-col {
        width: 100%;
        margin: auto 0%;
        text-align: center;
    }
}

@media (max-width: 361px) {
    #page-banner {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .page-banner-col {
        width: 100%;
        margin: auto 0%;
        text-align: center;
    }
}