#footer-container {
    background-color: #243557;
    padding: 1.5% 20% 2%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.footer-col:nth-child(1) {
    grid-column: span 4;
}

#footer-menu {
    margin-bottom: 3%;
    align-items: center!important;
    justify-content: center!important;
}

#footer-contact-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}

.footer-contact-col {
    text-align: center!important;
}

.footer-contact-col:nth-child(1), .footer-contact-col:nth-child(2) {
    grid-column: span 12;
}

.footer-nav-item {
    text-align: center!important;
}

.footer-nav-item, #phone-icon, #phone-number, #copyright {
    color: white!important;
}

#phone-number {
    font-weight: 600;
}

#footer-email-list {
    padding: 4%;
}

#footer-email-list-header {
    color: white;
    margin-bottom: 4%;
    font-weight: 700!important;
}

.footer-email-list-item {
    color: white!important;
}

.footer-email-list-item:hover {
    text-decoration: underline;
}

@media (max-width: 1699px) and (min-width: 1200px) {
    .footer-col:nth-child(1) {
        grid-column: span 8;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .footer-col:nth-child(1) {
        grid-column: span 8;
    }
    #footer-container {
        padding: 1.5% 10% 2%
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    .footer-col:nth-child(1) {
        grid-column: span 10;
    }
    #footer-container {
        padding: 1.5% 10% 2%
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    .footer-col:nth-child(1), .footer-col:nth-child(2) {
        grid-column: span 12;
    }
    #footer-container {
        padding: 1.5% 10% 2%
    }
    #footer-email-list-header {
        text-align: center;
    }
    .footer-email-list-item {
        text-align: center!important;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    .footer-col:nth-child(1), .footer-col:nth-child(2) {
        grid-column: span 12;
    }
    #footer-container {
        padding: 1.5% 7.5% 2%
    }
    #footer-email-list-header {
        text-align: center;
    }
    .footer-email-list-item {
        text-align: center!important;
    }
}

@media (max-width: 361px) {
    .footer-col:nth-child(1), .footer-col:nth-child(2) {
        grid-column: span 12;
    }
    #footer-container {
        padding: 1.5% 2% 2%
    }
    #footer-email-list-header {
        text-align: center;
    }
    .footer-email-list-item {
        text-align: center!important;
    }
}