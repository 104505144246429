.home-slideshow {
    overflow: hidden;
    max-width: 100%;
}

.home-slideshow-slider {
    white-space: nowrap;
    transition: ease 1s;
    margin-bottom: -0.25%;
}

.home-slide {
    display: inline-block;
    height: 100%;
    width: 100%;
}

.home-slideshow-dots {
    text-align: center;
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
}

.home-slideshow-dot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    cursor: pointer;
    margin: 15px 7px 0px;
    background-color: #c4c4c4;
}

.home-slideshow-dot.active {
    background-color: #838085;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    .home-slideshow-dots {
        width: 80%;
        margin: 0 10%;
    }

    .home-slideshow-slider {
        margin-bottom: -0.3%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    .home-slideshow-dots {
        width: 80%;
        margin: 0 10%;
    }

    .home-slideshow-slider {
        margin-bottom: -0.3%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .home-slideshow-dots {
        width: 80%;
        margin: 0 10%;
    }
    .home-slideshow-slider {
        margin-bottom: -0.45%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    .home-slideshow-dots {
        width: 70%;
        margin: 0 15%;
    }
    .home-slideshow-slider {
        margin-bottom: -0.5%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    .home-slideshow-dots {
        width: 70%;
        margin: 0 15%;
    }
    .home-slideshow-slider {
        margin-bottom: -0.65%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    .home-slideshow-dots {
        width: 70%;
        margin: 0 15%;
    }
    .home-slideshow-slider {
        margin-bottom: -1.1%;
    }
    .home-slideshow-dot {
        height: 10px;
        width: 10px;
    }
}

@media (max-width: 516px) {
    .home-slideshow-dots {
        width: 100%;
        margin: 0%;
    }
    .home-slideshow-slider {
        margin-bottom: -1.3%;
    }
    .home-slideshow-dot {
        height: 10px;
        width: 10px;
    }
}