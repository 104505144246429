 #announcement-container {
     display: grid;
     grid-template-columns: repeat(12, 1fr);
     background-color: #243557;
     padding: 1%;
 }

 .announce-col:nth-child(1) {
     grid-column: 3 / span 6;
 }

 .announce-col:nth-child(2) {
     grid-column: span 2;
 }

 #announce-phone-number {
     color: white;
     font-weight: 600;
     font-size: 16px;
     margin: 0 4% 0 0;
 }

 #announce-address {
     color: white;
     margin: 0;
 }

 .announce-social-icon-wrapper,
 .announce-contact-wrapper {
     display: flex;
 }

 #announce-facebook-icon {
     width: 5%;
     margin: 0 2.5%;
 }

 #announce-instagram-icon {
     width: 5%;
 }

 #welcome-user {
     margin: 0 6% 0 0;
 }

 @media (max-width: 2053px) and (min-width: 1700px) {
     .announce-col:nth-child(1) {
         grid-column: 3 / span 6;
     }

     .announce-col:nth-child(2) {
         grid-column: span 2;
     }

     #announce-facebook-icon {
        width: 7.5%;
        margin: 0 3%;
    }
   
    #announce-instagram-icon {
        width: 7.5%;
    }
 }

 @media (max-width: 1699px) and (min-width: 1200px) {
     .announce-col:nth-child(1) {
         grid-column: 3 / span 6;
     }

     .announce-col:nth-child(2) {
         grid-column: span 3;
     }

     #announce-facebook-icon {
        width: 7.5%;
        margin: 0 3%;
    }
   
    #announce-instagram-icon {
        width: 7.5%;
    }
 }

 @media (max-width: 1199px) and (min-width: 992px) {
     .announce-col:nth-child(1) {
         grid-column: 2 / span 7;
     }

     .announce-col:nth-child(2) {
         grid-column: span 4;
     }

     #announce-facebook-icon {
        width: 7.5%;
        margin: 0 3% 0 25%;
    }
   
    #announce-instagram-icon {
        width: 7.5%;
    }
 }

 @media (max-width: 991px) and (min-width: 801px) {
     .announce-col:nth-child(1) {
         grid-column: 3 / span 5;
     }

     .announce-col:nth-child(2) {
         grid-column: span 5;
     }

     #announce-facebook-icon {
        width: 7.5%;
        margin: 0 3% 0 25%;
    }
   
    #announce-instagram-icon {
        width: 7.5%;
    }
 }

 @media (max-width: 800px) and (min-width: 768px) {
    .announce-col:nth-child(1) {
        grid-column: 3 / span 5;
    }

    .announce-col:nth-child(2) {
        grid-column: span 5;
    }

    #announce-facebook-icon {
       width: 7.5%;
       margin: 0 3% 0 25%;
   }
  
   #announce-instagram-icon {
       width: 7.5%;
   }
 }

 @media (max-width: 767px) and (min-width: 524px) {
     #announcement-container {
         padding: 3%;
     }

     .announce-col:nth-child(1) {
        grid-column: 3 / span 5;
    }

    .announce-col:nth-child(2) {
        grid-column: span 5;
    }

    #announce-facebook-icon {
       width: 10%;
       margin: 0 3% 0 25%;
   }
  
   #announce-instagram-icon {
       width: 10%;
   }
 }

 @media (max-width: 523px) and (min-width: 433px) {
     #announcement-container {
         padding: 3%;
     }

     .announce-col:nth-child(1) {
        grid-column: 3 / span 5;
    }

    .announce-col:nth-child(2) {
        grid-column: span 5;
    }

    #announce-facebook-icon {
       width: 15%;
       margin: 0 5% 0 25%;
   }
  
   #announce-instagram-icon {
       width: 15%;
   }
 }

 @media (max-width: 432px) and (min-width: 362px) {
     #announcement-container {
         padding: 3%;
     }

     .announce-col:nth-child(1) {
         grid-column: 3 / span 6;
     }

     .announce-col:nth-child(2) {
         grid-column: span 4;
     }

     #announce-facebook-icon {
         width: 17.5%;
         margin: 0 5%;
     }

     #announce-instagram-icon {
         width: 17.5%;
     }
 }

 @media (max-width: 361px) {
     #announcement-container {
         padding: 3%;
     }

     .announce-col:nth-child(1) {
        grid-column: 3 / span 6;
    }

    .announce-col:nth-child(2) {
        grid-column: span 4;
    }

    #announce-facebook-icon {
        width: 17.5%;
        margin: 0 5%;
    }

    #announce-instagram-icon {
        width: 17.5%;
    }
 }