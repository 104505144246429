#our-services-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#our-services-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

#services-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    width: 90%;
    margin: 3% 5%;
    padding: 2%;
}

.services-card {
    border: solid 1px #d2caca;
    border-radius: 5px;
    padding: 5%;
    transition: transform .5s;
}

.services-card:hover {
    transform: scale(1.05);
}

.services-card-header {
    text-align: center;
    margin: 10% 0;
    font-size: 20px;
}

.services-card-btn {
    background-color: #f11515 !important;
    color: white !important;
    transition: ease 0.5s !important;
}

.services-card-btn:hover {
    background-color: #243557 !important;
    transition: ease 0.5s !important;
}

.services-card-image {
    width: 60%;
    margin: 0 20%;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    #our-services-container {
        width: 80%;
        margin: 0 10%;
    }

    #services-container { 
        width: 100%;
        margin: 0;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) { 
    #our-services-container {
        width: 80%;
        margin: 0 10%;
    }

    #services-container {
        grid-template-columns: repeat(5, 1fr); 
        width: 100%;
        margin: 0;
    }

    .services-card-description {
        height: 40%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #our-services-container {
        width: 80%;
        margin: 0 10%;
    }

    #services-container {
        grid-template-columns: repeat(3, 1fr); 
        width: 100%;
        margin: 0;
    }

    .services-col:nth-child(10) {
        grid-column: 2 / span 1;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #our-services-container {
        width: 70%;
        margin: 0 15%;
    }

    #services-container {
        grid-template-columns: repeat(1, 1fr); 
        width: 60%;
        margin: 0 20%;
    }

    .services-card-image { 
        width: 40%;
        margin: 0 30%;
    }

    .services-card-header {
        margin: 5% 0;
    }
    .services-card-btn {
        width: 50%!important;
        margin: 0 25%!important;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #our-services-container {
        width: 70%;
        margin: 0 15%;
    }

    #our-services-header {
        text-align: center;
        margin-top: 4%;
    }

    #services-container {
        grid-template-columns: repeat(1, 1fr); 
        width: 60%;
        margin: 4% 20%;
    }

    .services-card-image { 
        width: 40%;
        margin: 0 30%;
    }

    .services-card-header {
        margin: 5% 0;
    }
    .services-card-btn {
        width: 60%!important;
        margin: 0 20%!important;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #our-services-container {
        width: 70%;
        margin: 0 15%;
    }

    #our-services-header {
        text-align: center;
        margin: 4% 0 10%;
    }

    #services-container {
        grid-template-columns: repeat(1, 1fr); 
        width: 80%;
        margin: 5% 10%;
    }

    .services-card-image { 
        width: 30%;
        margin: 0 35%;
    }

    .services-card-header {
        margin: 5% 0;
    }
    .services-card-btn {
        width: 100%!important;
        margin: 0 0%!important;
    }
}

@media (max-width: 516px) {
    #our-services-container {
        width: 100%;
        margin: 0;
    }

    #our-services-header {
        text-align: center;
        margin: 4% 0 10%;
    }

    #services-container {
        grid-template-columns: repeat(1, 1fr); 
        width: 80%;
        margin: 5% 10%;
    }

    .services-card-image { 
        width: 30%;
        margin: 0 35%;
    }

    .services-card-header {
        margin: 5% 0;
    }

    .services-card-btn {
        width: 100%!important;
        margin: 0 0%!important;
    }
}

@media (max-width: 361px) {
    #our-services-header {
        text-align: center;
        margin-top: 4%;
        margin-bottom: 10%;
    }

    #services-container {
        grid-template-columns: repeat(1, 1fr); 
        width: 90%;
        margin: 0 5%;
    }

    .services-card-image { 
        width: 30%;
        margin: 0 35%;
    }

    .services-card-header {
        margin: 5% 0;
    }
    
    .services-card-btn {
        width: 100%!important;
    }
}