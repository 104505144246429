#home-about-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#home-about-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

.home-about-text {
    font-size: 20px;
}

#home-about-text-container {
    padding: 2%;
    width: 90%;
    margin: 0 5%;
}

.home-about-sublist-icon {
    color: #f11515;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    #home-about-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #home-about-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #home-about-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #home-about-container {
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #home-about-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-about-header {
        text-align: center;
        margin-top: 4%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #home-about-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-about-header {
        text-align: center;
        margin-top: 4%;
    }
}

@media (max-width: 516px) {
    #home-about-container {
        width: 100%;
        margin: 0%;
    }

    #home-about-header {
        text-align: center;
        margin: 4% 0 10%;
    }

    #paragraph2 {
        margin-bottom: 0%;
    }
}