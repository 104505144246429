#home-trust-badges-container {
    background-color: #f11515;
    padding: 2%;
    padding-bottom: 3%;
}

#trust-badges-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 60%;
    margin: 0 20%;
}

.trust-badge-col {
    width: 100%;
    margin: 0 0%;
}

.trust-badge-image {
    width: 30%;
    margin: 0 35%;
}

.trust-badge-title, .trust-badge-text {
    text-align: center!important;
    color: white!important;
}

.trust-badge-title {
    font-size: 24px!important;
}

@media (max-width: 2030px) and (min-width: 1700px) {
    #trust-badges-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #trust-badges-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #trust-badges-container {
        width: 90%;
        margin: 0 5%;
    }
    .trust-badge-title {
        font-size: 20px!important;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #trust-badges-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #trust-badges-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #home-trust-badges-container {
        padding-bottom: 10%;
    }
    #trust-badges-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
        width: 80%;
        margin: 0 10%;
    }
    .trust-badge-image {
        width: 40%;
        margin: 0 30%;
    }
}

@media (max-width: 361px) {
    #home-trust-badges-container {
        padding-bottom: 10%;
    }
    #trust-badges-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
        width: 80%;
        margin: 0 10%;
    }
    .trust-badge-image {
        width: 40%;
        margin: 0 30%;
    }
}