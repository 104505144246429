#home-reviews-container {
    background-color: #f11515;
    width: 100%;
    margin: 0%;
    border: solid 1px #f11515;
    padding-top: 2%;
}

.home-reviews-text {
    width: 80%;
    margin: 0% 10%;
    font-size: 18px;
    text-align: center;
    color: white;
}

#home-reviews {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    width: 60%;
    margin: 0 20%;
    column-gap: 20px;
    row-gap: 20px;
    padding: 2% 3%;
}

#home-reviews-no-result-container {
    width: 30%;
    margin: 5% 35%;
    text-align: center;
}

#home-reviews-no-result-image {
    width: 40%;
    margin: 0 30%;
}

.home-review-card {
    border: solid 1px #ff0000;
    border-radius: 5px;
    padding: 5%;
    transition: transform .5s;
    height: 460px;
    background-color: #d40000;
}

.home-review-card:hover {
    transform: scale(1.05);
}

.home-review-card-btn-container {
    width: 35% !important;
    margin: 0 0 0 65% !important;
}

.home-review-card-icon {
    color: white;
}

.home-review-card-image {
    width: 100%;
    height: 200px;
    object-fit: contain;
    border-radius: 5px;
    margin-bottom: 10%!important;
}

.home-review-card-image,
.home-review-card-rating {
    margin: 2% 0;
}

.ui.rating .active.icon {
    color: #fff!important;
}

.home-review-card-text {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.home-review-card-text,
.home-review-card-date,
.home-review-card-author {
    color: white;
    margin: 5% 0;
}

#home-reviews-btn {
    background-color: transparent !important;
    color: white !important;
    border: solid #fff !important;
    font-size: 18px !important;
    width: 20%;
    margin: 0 40% 2%;
    transition: ease 0.5s !important;
}

#home-reviews-btn:hover {
    background-color: #fefcfd !important;
    color: #f11515 !important;
    transition: ease 0.5s !important;
}

@media (max-width: 2053px) and (min-width: 1700px) {
    #write-home-review-btn {
        width: 25%;
    }

    #home-reviews {
        grid-template-columns: repeat(5, 1fr);
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #write-home-review-btn {
        width: 30%;
    }

    #home-reviews {
        grid-template-columns: repeat(5, 1fr);
        width: 90%;
        margin: 0 5%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #write-home-review-btn {
        width: 40%;
    }

    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(6, 1fr);
        width: 90%;
        margin: 0 5%;
    }

    .home-review-card-col {
        grid-column: span 2;
    }

    #home-reviews-btn {
        width: 30%;
        margin: 0 35% 2%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #write-home-review-btn {
        width: 45%;
    }

    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        margin: 0 10%;
    }

    #home-reviews-btn {
        width: 30%;
        margin: 0 35% 2%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #write-home-review-btn {
        width: 50%;
    }

    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(2, 1fr);
        width: 80%;
        margin: 0 10%;
    }

    #home-reviews-btn {
        width: 30%;
        margin: 0 35% 2%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(1, 1fr);
        width: 70%;
        margin: 0 15%;
    }

    #home-reviews-btn {
        width: 40%;
        margin: 0 30% 2%;
    }
}

@media (max-width: 516px) and (min-width: 362px) {
    #write-home-review-btn {
        width: 80%;
        float: none;
    }

    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(1, 1fr);
        width: 80%;
        margin: 0 10%;
    }

    #home-reviews-btn {
        width: 60%;
        margin: 3% 20% 5%;
    }
}

@media (max-width: 361px) {
    .home-reviews-text {
        margin: 5% 10% 3%;
    }

    #home-reviews {
        grid-template-columns: repeat(1, 1fr);
        width: 90%;
        margin: 0 5%;
    }

    #home-reviews-btn {
        width: 70%;
        margin: 3% 15% 5%;
    }
}