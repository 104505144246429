#main-menu,
#mobile-main-menu {
    margin: 0!important;
    padding: 1% 10%;
    background-color: #fefcfd;
}

.header-container {
    position: relative;
    z-index: 999;
}

.sticky {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100%!important;
}

#mobile-sidebar {
    width: 80%;
}

#logout-btn, #login-btn, #welcome-user {
    color: #fefcfd;
}

#logout-btn {
    cursor: pointer;
}

.header-nav-item {
    font-size: 20px;
    text-decoration: underline !important;
    text-underline-offset: 20px;
    text-decoration-color: #f11515 !important;
    transition: ease .3s !important;
}

.header-nav-item:hover {
    background-color: #fefcfd !important;
    color: #243557;
    text-underline-offset: 10px;
    text-decoration-color: #243557 !important;
    transition: ease .3s !important;
}

.dropdown.icon {
    text-decoration: none !important;
}

.ui.secondary.menu .active.item {
    background: none !important;
}

.ui.secondary.menu .active.item:hover {
    background: none !important;
}

@media (max-width: 991px) {
    .header-nav-item {
        text-decoration: none !important;
    }

    .header-nav-item:hover {
        text-decoration: none !important;
        background-color: transparent !important;
    }

    #header-nav-stack {
        font-size: 28px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #main-menu {
        padding: 1% 5%;
    }

    .header-nav-item {
        font-size: 18px;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #main-menu {
        padding: 2% 2%;
    }
}