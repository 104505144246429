#social-links-container {
    background-color: #fefcfd;
    padding: 2% 0;
    
}

#social-links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 20%;
    margin: 0 40%;
}
.social-col:nth-child(1) {
    grid-column: span 2;
}

#social-links-header {
    font-weight: 900;
    width: 80%;
    margin: 0 10% 5%;
    text-align: center;
}

#footer-facebook-icon {
    width: 20%;
    margin: 0 0 0 75%;
}

#footer-instagram-icon {
    width: 20%;
    margin: 0 0 0 4%;
}

@media (max-width: 1199px) and (min-width: 992px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #social-links {
        width: 30%;
        margin: 0 35%;
    }
}

@media (max-width: 767px) and (min-width: 362px) {
    #social-links {
        width: 60%;
        margin: 0 20%;
    }
}

@media (max-width: 361px) {
    #social-links {
        width: 70%;
        margin: 0 15%;
    }
}