#home-video-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
    padding: 2%;
}

#home-video-iframe {
    width: 60%;
    margin: 0 20%;
    height: 500px;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    #home-video-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #home-video-container {
        width: 80%;
        margin: 0 10%;
    }

    #home-video-iframe {
        height: 400px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #home-video-container {
        width: 80%;
        margin: 0 10%;
    }

    #home-video-iframe {
        width: 80%;
        margin: 0 10%;
        height: 400px;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #home-video-container {
        width: 80%;
        margin: 0 10%;
    }

    #home-video-iframe {
        width: 80%;
        margin: 0 10%;
        height: 400px;
    }
}

@media (max-width: 800px) and (min-width: 601px) {
    #home-video-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-video-iframe {
        width: 80%;
        margin: 0 10%;
        height: 300px;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #home-video-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-video-iframe {
        width: 95%;
        margin: 0 2.5%;
        height: 300px;
    }
}

@media (max-width: 516px) {
    #home-video-container {
        width: 100%;
        margin: 0;
    }

    #home-video-iframe {
        width: 95%;
        margin: 0 2.5%;
        height: 300px;
    }
}