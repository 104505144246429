#review-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
}

#review-header-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 20px;
    width: 96%;
    margin: 2% 2%;
}
.review-header-col:nth-child(1) {
    grid-column: 1 / span 6;
}

.review-header-col:nth-child(2) {
    grid-column: 7 / span 6;
    text-align: right;
}

#review-header {
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

#review-text {
    width: 80%;
    margin: 0 10%;
    font-size: 20px;
    text-align: left;
}

#review-rating {
    margin-left: 10%;
    margin-bottom: 2%;
}

#review {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
    padding: 2% 3%;
}

#review-date {
    margin-left: 10%;
    margin-top: 2%;
}

#review-edit-btn, #review-delete-btn {
    background-color: #f11515 !important;
    color: white !important;
    transition: ease 0.5s !important;
}

#review-edit-btn:hover, #review-delete-btn:hover {
    background-color: #243557 !important;
    transition: ease 0.5s !important;
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #review-container {
        width: 70%;
        margin: 0 15%;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #review-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 991px) {
    #review-container {
        width: 70%;
        margin: 0 15%;
        padding: 2%;
    }

    .review-col:nth-child(1) {
        grid-column: 1 / span 2;
    }

    .review-col:nth-child(2) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 2;
    }

    #review-header-container {
        margin: 2% 2% 5%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #review-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 516px) {
    #review-container {
        width: 100%;
        margin:  5% 0%;
    }

    #review-header-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 30px;
        column-gap: 20px;
    }

    .review-header-col:nth-child(1) {
        grid-column: 1 / span 1;
        text-align: center;
    }
    
    .review-header-col:nth-child(2) {
        grid-column: 1 / span 1;
        text-align: center;
    }
}

@media (max-width: 361px) {

    #review-header {
        font-size: 24px;
    }

    #review-text {
        font-size: 18px;
    }
}