#about-page-container {
    background-color: #fefcfd;
    width: 60%;
    margin: 0 20%;
    border: solid 1px #fefcfd;
    padding: 2%;
}

#about-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
}

#zuse-building-img {
    width: 100%;
}

#about-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
    text-align: center;
    margin-bottom: 6%;
}

#about-text {
    font-size: 18px;
}

#contact-us-btn {
    width: 30%;
    height: 60px;
    background-color: #f11515;
    transition: ease 0.5s !important;
    border: solid 1px transparent;
    color: #fefcfd;
    font-family: "Lato", "Helvetica Neuve";
    margin: 6% 35% 0;
}

#contact-us-btn:hover {
    background-color: #243557;
    transition: ease 0.5s !important;
}

@media (min-width: 1700px) {
    #zuse-building-img {
        margin-top: 10%;
        margin-left: -2%;
    }
}


@media (max-width: 2030px) and (min-width: 1700px),
(max-width: 1699px) and (min-width: 1200px),
(max-width: 1199px) and (min-width: 992px) { 
    #about-page-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #about-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
}

@media (max-width: 1199px) {
    #about-page-container {
        width: 70%;
        margin: 0 15%;
    }
    #about-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }
    #contact-us-btn {
        margin: 3% 35%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #contact-us-btn {
        width: 70%;
        margin: 3% 15%;
    }
    #about-header {
        line-height: 60px;
    }
}

@media (max-width: 516px) {
    #about-page-container {
        width: 100%;
        margin: 0%;
        padding: 5%;
    }
    #contact-us-btn {
        width: 100%;
        margin: 5% 0%;
    }
    #about-header {
        line-height: 60px;
    }
}