#contact-form-container, #thank-you-container {
    background-color: #fefcfd;
    width: 60%;
    margin: -0.2% 20% 0;
    border: solid 1px #fefcfd;
    padding: 2%;
}

#thank-you-container:nth-child(1), #thank-you-container:nth-child(2) {
    text-align: center;
}

#form-field-container {
    display: grid;
    width: 80%;
    margin: 0 10% 3%;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 40px;
}

#contact-phone-number, #contact-address {
    text-align: center;
    font-size: 20px;
}

#name-input, #email-input, #company-input, #phone-input {
    width: 100%;
    font-family: "Lato", "Helvetica Neuve";
    height: 50px;
    font-size: 20px;
    background-color: #f6f6f6;
    border: solid 1px #f6f6f6;
    padding: 0 2%;
}

#message-input {
    width: 100%;
    font-family: "Lato", "Helvetica Neuve";
    resize: none;
    height: 100px;
    font-size: 20px;
    background-color: #f6f6f6;
    border: solid 1px #f6f6f6;
    padding: 1% 1%;
}

#submit-btn {
    width: 40%;
    height: 60px;
    background-color: #f11515;
    transition: ease 0.5s !important;
    border: solid 1px #fefcfd;
    color: #fefcfd;
    font-family: "Lato", "Helvetica Neuve";
    margin: 0% 30% 3%;
    cursor: pointer !important;
    border-radius: .28571429rem;
}

#submit-btn:hover {
    background-color: #243557;
    transition: ease 0.5s !important;
}

.form-field-col:nth-child(1) {
    grid-column: span 2;
}

.form-field-col:nth-child(2) {
    grid-column: span 2;
}

.form-field-col:nth-child(3) {
    grid-column: span 2;
}

.form-field-col:nth-child(4) {
    grid-column: span 2;
}

.form-field-col:nth-child(5) {
    grid-column: span 2;
}

.form-field-col:nth-child(6) {
    grid-column: span 2;
}

.form-field-col:nth-child(7) {
    grid-column: span 4;
}

.form-field-col:nth-child(8) {
    grid-column: 2 / span 1;
    text-align: end;
}

.form-field-col:nth-child(9) {
    grid-column: span 1;
    text-align: start;
}

@media (max-width: 2030px) and (min-width: 1700px) { 
    #contact-form-container, #thank-you-container {
        width: 80%;
        margin: -0.25% 10% 0;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {
    #contact-form-container, #thank-you-container {
        width: 80%;
        margin: -0.35% 10% 0;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    #contact-form-container, #thank-you-container {
        width: 80%;
        margin: -0.5% 10% 0;
    }

    #contact-phone-number {
        margin-top: 2%;
    }

    #form-field-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .form-field-col:nth-child(1) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(2) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(3) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(4) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(5) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(6) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(7) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(8) {
        grid-column: 2 / span 1;
        text-align: end;
    }
    
    .form-field-col:nth-child(9) {
        grid-column: span 1;
        text-align: start;
    }
}

@media (max-width: 991px) and (min-width: 801px) {
    #contact-form-container, #thank-you-container {
        width: 70%;
        margin: -0.6% 15% 0;
    }

    #contact-phone-number {
        margin-top: 2%;
    }

    #form-field-container {
        grid-template-columns: repeat(1, 1fr);
    }

    .form-field-col:nth-child(1) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(2) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(3) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(4) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(5) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(6) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(7) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(8) {
        grid-column: span 3;
        
    }
    
    .form-field-col:nth-child(9) {
        grid-column: span 1;
        
    }
}

@media (max-width: 800px) and (min-width: 768px) {
    #contact-form-container, #thank-you-container {
        width: 70%;
        margin: -0.6% 15% 0;
    }

    #contact-phone-number {
        margin-top: 2%;
    }

    #form-field-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }

    .form-field-col:nth-child(1) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(2) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(3) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(4) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(5) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(6) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(7) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(8) {
        grid-column: span 4;
        text-align: center;
        
    }
    
    .form-field-col:nth-child(9) {
        grid-column: span 4;
        text-align: center;
        margin-left: 18%;
    }
}

@media (max-width: 767px) and (min-width: 517px) {
    #contact-form-container, #thank-you-container {
        width: 70%;
        margin: -1.3% 15% 0;
    }

    #contact-phone-number, #contact-address {
        text-align: left;
        font-size: 16px;
    }

    #contact-phone-number {
        margin-top: 2%;
    }

    #name-input, #email-input, #company-input, #phone-input {
        font-size: 16px;
        height: 40px;
    }

    #message-input {
        font-size: 16px;
    }

    #form-field-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }

    .form-field-col:nth-child(1) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(2) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(3) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(4) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(5) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(6) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(7) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(8) {
        grid-column: span 4;
        text-align: center;
    }
    
    .form-field-col:nth-child(9) {
        grid-column: span 4;
        text-align: center;
        margin-left: 6%;
        margin-bottom: 5%;
    }

    #submit-btn {
        width: 60%;
        margin: 0 20%;
    }
}

@media (max-width: 516px) {
    #contact-form-container, #thank-you-container {
        width: 100%;
        margin: -1.5% 0 0;
        padding: 5%;
    }

    #contact-phone-number, #contact-address {
        text-align: left;
        font-size: 14px;
    }

    #contact-phone-number {
        margin-top: 2%;
    }

    #name-input, #email-input, #company-input, #phone-input {
        font-size: 14px;
        height: 40px;
    }

    #message-input {
        font-size: 14px;
    }

    #form-field-container {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 20px;
    }

    .form-field-col:nth-child(1) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(2) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(3) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(4) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(5) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(6) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(7) {
        grid-column: span 4;
    }
    
    .form-field-col:nth-child(8) {
        grid-column: span 4;
        text-align: center;
    }
    
    .form-field-col:nth-child(9) {
        grid-column: span 4;
        text-align: center;
        margin-bottom: 5%;
    }

    #submit-btn {
        width: 60%;
        margin: 0 20%;
    }
}