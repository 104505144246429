#home-contact-form-container,
#home-thank-you-container {
    background-color: #fefcfd;
    background-size: cover;
    width: 60%;
    margin: 0% 20% 0;
    padding: 4%;
}

#thank-you,
#in-touch {
    text-align: center;
    color: #000000;
}

#home-contact-header {
    padding: 2%;
    text-decoration: underline;
    text-underline-offset: 20px;
    text-decoration-color: #f11515;
}

#home-contact-subheader {
    padding: 2%;
    width: 80%;
    line-height: 40px;
}

#home-contact-phone-number,
#home-contact-address {
    margin: 5% 0;
    font-size: 20px;
}

#home-name-input,
#home-email-input,
#home-company-input,
#home-phone-input {
    font-family: "Lato", "Helvetica Neuve";
    width: 100%;
    height: 50px;
    font-size: 20px;
    border: solid 1px #000000;
    padding: 0 4%;
    border-radius: 5px;
}

#home-message-input {
    font-family: "Lato", "Helvetica Neuve";
    width: 100%;
    height: 100px;
    resize: none;
    font-size: 20px;
    border: solid 1px #000000;
    padding: 2%;
    border-radius: 5px;
    color: #000000;
}

#home-name-input::placeholder,
#home-email-input::placeholder,
#home-company-input::placeholder,
#home-phone-input::placeholder,
#home-message-input::placeholder {
    color: #000000;
}

#upload-label {
    color: #000000;
}

#home-art-file {
    color: #000000;
}

#home-submit-btn {
    width: 40%;
    height: 60px;
    background-color: #f11515;
    transition: ease 0.5s !important;
    border: solid 1px transparent;
    color: #fefcfd;
    font-family: "Lato", "Helvetica Neuve";
    margin: 6% 30% 0;
    cursor: pointer !important;
    border-radius: .28571429rem;
}

#home-submit-btn:hover {
    background-color: #243557;
    transition: ease 0.5s !important;
}

@media (max-width: 2030px) and (min-width: 1700px) {
    #home-contact-form-container,
    #home-thank-you-container {
        width: 80%;
        margin: 0 10%;
    }
}

@media (max-width: 1699px) and (min-width: 1200px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 80%;
        margin: 0 10%;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 80%;
        margin: 0 10%;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
        font-size: 16px;
    }

    #home-message-input {
        font-size: 16px;
    }

    #home-submit-btn {
        width: 50%;
        margin: 6% 25% 0;
        height: 50px;
    }
}

@media (max-width: 991px) and (min-width: 801px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-form-field-row:nth-child(1) {
        width: 100% !important;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
        font-size: 16px;
    }

    #home-name-input,
    #home-phone-input {
        margin-bottom: 28px;
    }

    #home-message-input {
        font-size: 16px;
        padding: 2% 3.5%;
    }

    #home-submit-btn {
        width: 50%;
        margin: 6% 25% 0;
        height: 50px;
    }
}

@media (max-width: 800px) and (min-width: 768px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-form-field-row:nth-child(1) {
        width: 100% !important;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
        font-size: 16px;
    }

    #home-name-input,
    #home-phone-input {
        margin-bottom: 28px;
    }

    #home-message-input {
        font-size: 16px;
        padding: 2% 3.5%;
    }

    #home-submit-btn {
        width: 50%;
        margin: 6% 25% 0;
        height: 50px;
    }

    #home-contact-header {
        text-align: center;
    }
}

@media (max-width: 767px) and (min-width: 517px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 70%;
        margin: 0 15%;
    }

    #home-form-field-row:nth-child(1) {
        width: 100% !important;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
        font-size: 16px;
    }

    #home-name-input,
    #home-phone-input {
        margin-bottom: 28px;
    }

    #home-message-input {
        font-size: 16px;
        padding: 2% 3.5%;
    }

    #home-submit-btn {
        width: 50%;
        margin: 6% 25% 0;
        height: 50px;
    }

    #home-contact-header {
        text-align: center;
        font-size: 20px;
    }

    #home-contact-subheader {
        text-align: center;
        width: 80%;
        margin: 0 10%;
    }

    #home-contact-phone-number,
    #home-contact-address {
        font-size: 16px;
        font-weight: 600;
        width: 80%;
        margin: 5% 10% 10%;
    }

    #upload-label, #home-art-file {
        width: 80%;
        margin: 0 10%;
    }

    .sixteen.wide.column:has(#upload-label) {
        margin-bottom: 5%;
        text-align: center;
    }

    .sixteen.wide.column:has(#home-art-file) {
        text-align: center;
    }
}

@media (max-width: 516px) {

    #home-contact-form-container,
    #home-thank-you-container {
        width: 100%;
        margin: 0%;
    }

    #home-form-field-row:nth-child(1) {
        width: 100% !important;
    }

    #home-name-input,
    #home-email-input,
    #home-company-input,
    #home-phone-input {
        height: 40px;
        font-size: 16px;
    }

    #home-name-input,
    #home-phone-input {
        margin-bottom: 28px;
    }

    #home-message-input {
        font-size: 16px;
        padding: 2% 3.5%;
    }

    #home-submit-btn {
        width: 60%;
        margin: 6% 20% 0;
        height: 50px;
    }

    #home-contact-header {
        text-align: center;
        font-size: 28px;
    }

    #home-contact-subheader {
        text-align: center;
        width: 80%;
        margin: 0 10%;
    }

    #home-contact-phone-number,
    #home-contact-address {
        font-size: 14px;
        width: 90%;
        margin: 5% 5% 10%;
    }

    #upload-label, #home-art-file {
        width: 80%;
        margin: 0 10%;
    }

    .sixteen.wide.column:has(#upload-label) {
        margin-bottom: 5%;
        text-align: center;
    }

    .sixteen.wide.column:has(#home-art-file) {
        text-align: center;
    }
}